import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

// Modules
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { FontAwesome } from "../../../../modules/font-awesome/font-awesome.module";
import { SharedModule } from "../../../../modules/shared/shared.module";

// Forms
import { FormsModule } from "@angular/forms";

// Components
import { RolesTableComponent } from "./roles-table.component";

@NgModule({
    imports: [CommonModule, FormsModule, NgbModule, FontAwesome, SharedModule],
    declarations: [RolesTableComponent],
    exports: [RolesTableComponent]
})
export class RolesTableModule {}
