import { Component, OnChanges, Input, SimpleChanges, ComponentRef } from "@angular/core";
import { TranslateService } from "@ngx-translate/core";
import { BehaviorSubject } from "rxjs";
import { TableSchema } from "src/app/components/shared/table-list/table-list.component";
import { RolesPermissionsColumnComponent } from "src/app/components/shared/table-list/tables-components/roles-permissions-column/roles-permissions-column.component";
import { ZxNgbHighlightComponent } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.component";
import { assignNgbHighlightInputsFactory } from "src/app/components/shared/zx-ngb-highlight/zx-ngb-highlight.table-adapter";
import { Role } from "src/app/models/shared";
import { RolesService } from "../roles.service";

@Component({
    selector: "app-roles-table",
    templateUrl: "./roles-table.component.html"
})
export class RolesTableComponent implements OnChanges {
    @Input() roles: Role[];
    @Input() showSource?: boolean;

    private rolesBS$ = new BehaviorSubject<Role[]>([]);

    tableColumnsSchema: TableSchema[] = [
        {
            header: this.translate.instant("NAME"),
            columnDef: "name",
            width: 160,
            visible: true,
            sticky: 1,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<Role>(
                row => row.name,
                row => row.name,
                () => true
            ),
            sortBy: (row: Role): string => row.name,
            textValue: (row: Role): string => row.name
        },
        {
            header: this.translate.instant("TAG"),
            columnDef: "access_tag",
            width: 100,
            visible: true,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<Role>(
                row => row.resourceTag.name,
                row => row.resourceTag.name,
                () => true
            ),
            sortBy: (row: Role): string => row.resourceTag.name,
            textValue: (row: Role): string => row.resourceTag.name
        },
        // Role Source?
        {
            header: this.translate.instant("ROLE_SOURCE"),
            columnDef: "role_source",
            width: 100,
            visible: this.showSource ?? false,
            component: ZxNgbHighlightComponent,
            assignComponentsInputs: assignNgbHighlightInputsFactory<Role>(
                row => row.source,
                row => row.source,
                () => true
            ),
            sortBy: (row: Role): string => row.source,
            textValue: (row: Role): string => row.source
        },
        //
        {
            header: this.translate.instant("RESOURCES"),
            columnDef: "resources",
            width: 50,
            visible: true,
            component: RolesPermissionsColumnComponent,
            assignComponentsInputs: (compRef: ComponentRef<RolesPermissionsColumnComponent>, role: Role) => {
                const componentInstance = compRef.instance;
                componentInstance.view = role._frontData.resources_read;
                componentInstance.edit = role._frontData.resources_write;
                componentInstance.notify = role._frontData.resources_notify;
            }
        },
        {
            header: this.translate.instant("CHANNELS"),
            columnDef: "channels",
            width: 50,
            visible: true,
            component: RolesPermissionsColumnComponent,
            assignComponentsInputs: (compRef: ComponentRef<RolesPermissionsColumnComponent>, role: Role) => {
                const componentInstance = compRef.instance;
                componentInstance.view = role._frontData.channels_read;
                componentInstance.edit = role._frontData.channels_write;
                componentInstance.notify = role._frontData.channels_notify;
            }
        },
        {
            header: this.translate.instant("SOURCES"),
            columnDef: "sources",
            width: 50,
            visible: true,
            component: RolesPermissionsColumnComponent,
            assignComponentsInputs: (compRef: ComponentRef<RolesPermissionsColumnComponent>, role: Role) => {
                const componentInstance = compRef.instance;
                componentInstance.view = role._frontData.sources_read;
                componentInstance.edit = role._frontData.sources_write;
                componentInstance.notify = role._frontData.sources_notify;
            }
        },
        {
            header: this.translate.instant("TARGETS"),
            columnDef: "targets",
            width: 50,
            visible: true,
            component: RolesPermissionsColumnComponent,
            assignComponentsInputs: (compRef: ComponentRef<RolesPermissionsColumnComponent>, role: Role) => {
                const componentInstance = compRef.instance;
                componentInstance.view = role._frontData.targets_read;
                componentInstance.edit = role._frontData.targets_write;
                componentInstance.notify = role._frontData.targets_notify;
            }
        },
        {
            header: this.translate.instant("REPORTS"),
            columnDef: "reports",
            width: 50,
            visible: true,
            component: RolesPermissionsColumnComponent,
            assignComponentsInputs: (compRef: ComponentRef<RolesPermissionsColumnComponent>, role: Role) => {
                const componentInstance = compRef.instance;
                componentInstance.view = role._frontData.reports_read;
                componentInstance.edit = role._frontData.reports_write;
                componentInstance.notify = role._frontData.reports_notify;
            }
        }
    ];

    constructor(private translate: TranslateService, private rs: RolesService) {}

    async ngOnChanges(changes: SimpleChanges) {
        if (changes.roles) {
            this.prepTableData();
        }
    }

    private prepTableData() {
        if (this.roles) {
            const roles = [];
            for (const role of this.roles) {
                roles.push(this.rs.prepRole(role, false));
            }
            this.rolesBS$.next([...roles]);
        }
    }

    get roles$() {
        return this.rolesBS$.asObservable();
    }
}
