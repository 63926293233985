<!-- Table w/ Source -->
<app-table-list
    *ngIf="showSource"
    [tableName]="'roles_showsource'"
    [displayTableName]="'ROLES' | translate"
    [data]="roles$ | async"
    [(tableSchema)]="tableColumnsSchema"
    [showReport]="false"
    [showSelectionCheckbox]="false"
    [showFilter]="false"
    [showPagination]="false"
    [selectable]="false"
    [hoverable]="false"
>
</app-table-list>
<!-- Table w/o Source -->
<app-table-list
    *ngIf="!showSource"
    [tableName]="'roles'"
    [displayTableName]="'ROLES' | translate"
    [data]="roles$ | async"
    [(tableSchema)]="tableColumnsSchema"
    [showReport]="false"
    [showSelectionCheckbox]="false"
    [showFilter]="false"
    [showPagination]="false"
    [selectable]="false"
    [hoverable]="false"
>
</app-table-list>
